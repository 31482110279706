<template>
  <div class="container">
    <PrimePreloader v-if="loading"/>
    <div v-else class="my-4">
      <h3 class="text-center my-4">Тест "Определение уровня воображения"</h3>

      <h4 v-if="result" class="my-4 text-center bg-light p-2">
        {{ result.text_ru }}
      </h4>
      <div v-else>
        <div v-if="checkAccess === 0"><h1 class="text-center mt-3 mb-3">Время сдачи теста еще не настало!</h1></div>
        <div v-else>
          <DataTable :value="questions" showGridlines>

            <Column field="question" header="Вопрос"></Column>

            <Column header="Ответ">
              <template #body="{data}">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio"
                         :value="{imagination_question_id: data.id, score: data.positive}" v-model="answers[data.id]">
                  <label class="form-check-label">Да</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio"
                         :value="{imagination_question_id: data.id, score: data.negative}" v-model="answers[data.id]">
                  <label class="form-check-label">Нет</label>
                </div>
              </template>
            </Column>

          </DataTable>

          <div class="my-3 text-center">
            <Button label="Сохранить" @click="saveAnswers"/>
          </div>
        </div>

      </div>


    </div>
  </div>
</template>


<script>

import {mapActions, mapState} from 'vuex'

export default {
  name: "CreativeImaginationTest",
  data() {
    return {
      loading: true,
      answers: {}
    }
  },
  computed: {
    ...mapState('creativeImaginationTest', ['questions', 'result']),
    ...mapState('checkAccess', ['checkAccess']),
  },
  methods: {
    ...mapActions('creativeImaginationTest', ['GET_QUESTIONS', 'POST_ANSWERS', 'GET_RESULT']),
    ...mapActions('checkAccess', ['GET_CHECK_ACCESS']),

    async saveAnswers() {
      const form = Object.values(this.answers)

      if (form.length !== this.questions.length) {
        return this.$error({title: 'Ответьте на все вопросы'})
      }

      const res = await this.POST_ANSWERS(form)
      if (res) {
        await this.GET_RESULT()
        this.$message({title: 'Сохранено'})
      }
    }
  },
  async mounted() {
    await this.GET_CHECK_ACCESS('imagination');
    await this.GET_RESULT()

    if (!this.result) {
      await this.GET_QUESTIONS()
    }

    this.loading = false
  }
}
</script>

<style scoped>

</style>
